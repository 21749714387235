.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.App-header {
	background-color: #222;
	height: 150px;
	padding: 20px;
	color: white;
}

.App-title {
	font-size: 1.5em;
}

.App-intro {
	font-size: large;
}

button:focus {
	background-color: #84c20e;
}
button:hover {
	background-color: #84c20e !important;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.rs-input {
	padding-left: 15px !important;
	padding-top: 5px !important;
}

.rs-picker-menu.rs-picker-select-menu {
	z-index: 2000;
}